<template>
  <div>
    <h3 class="text-center my-2">Valide os dados do tomador</h3>

    <validation-observer ref="formAtualizarDadosTomador">
      <b-row>
        <b-col md="12">
          <b-form-group label="Nome" label-for="form-name">
            <validation-provider #default="{ errors }" name="Nome" rules="required">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <FeatherIcon icon="UserIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="form-name"
                  name="form-name"
                  placeholder="Nome"
                  :disabled="isBusy"
                  v-model="dados.nome"
                ></b-form-input>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <b-form-group label="CPF" label-for="form-CPF">
            <validation-provider #default="{ errors }" name="CPF" rules="required">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <FeatherIcon icon="BookOpenIcon" />
                </b-input-group-prepend>

                <cleave
                  id="form-CPF"
                  type="text"
                  placeholder="CPF"
                  v-model="dados.cpf"
                  :raw="false"
                  :options="options.cleaveCPF"
                  class="form-control"
                  @input="removePontos"
                  :disabled="isBusy"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <b-form-group label="Matrícula" label-for="form-matricula">
            <validation-provider #default="{ errors }" name="Matrícula" rules="required">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <FeatherIcon icon="CreditCardIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="form-matricula"
                  name="form-matricula"
                  placeholder="Matrícula"
                  :disabled="isBusy"
                  v-model="dados.matricula"
                ></b-form-input>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <b-form-group label="Margem Disponível" label-for="form-margem-disponivel" class="mb-2">
            <validation-provider #default="{ errors }" name="Margem Disponível" rules="required">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <FeatherIcon icon="DollarSignIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="form-margem-disponivel"
                  name="form-margem-disponivel"
                  placeholder="Margem Disponível"
                  v-number="number"
                  :disabled="isBusy"
                  v-model="dados.margem"
                ></b-form-input>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>

    <b-row>
      <b-col md="12">
        <b-button block variant="primary" @click="atualizarDados" :disabled="isBusy">
          {{ !isBusy ? 'Atualizar Dados' : '' }}
          <b-spinner small v-if="isBusy" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarParaNumber, formatarValor } from '@/libs/utils'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Cleave from 'vue-cleave-component'

  export default {
    name: 'AtualizacaoDadosSegurado',
    components: {
      ValidationObserver,
      ValidationProvider,
      Cleave,
    },
    props: {
      segurado: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        number: {},
        isBusy: false,
        dados: {
          nome: this.segurado.nome,
          cpf: this.segurado.cpf,
          matricula: this.segurado.matricula,
          margem: this.segurado.margem,
        },
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
            numericOnly: true,
          },
        },
      }
    },
    mounted() {
      // Monta formulário
      this.dados = {
        nome: this.segurado.nome,
        cpf: this.segurado.cpf,
        matricula: this.segurado.matricula,
        margem: formatarValor(this.segurado.margem),
      }
    },
    methods: {
      async atualizarDados() {
        try {
          this.isBusy = true
          if (!(await this.$refs.formAtualizarDadosTomador.validate())) {
            this.$message.warning('Preencha todos os campos obrigatórios')
            return
          }

          const parametro = {
            id: this.segurado.seguradoId,
            nome: this.dados.nome,
            cpf: this.dados.cpf,
            matricula: this.dados.matricula,
            margem: formatarParaNumber(this.dados.margem),
          }
          await useJwt.patch('cadastro/tomador/atualizarDadosTomador', parametro)
          this.$emit('completaAtualizacaoSegurado', true, parametro)
        } catch (error) {
          console.error(error)
          this.$message.error('Erro ao atualizar dados do tomador')
          this.$emit('completaAtualizacaoSegurado', false)
        } finally {
          this.isBusy = false
        }
      },
      removePontos() {
        this.dados.cpf = this.dados.cpf.replace(/[^d]/g, '')
      },
    },
  }
</script>
