<template>
  <div>
    <b-form>
      <b-card class="card-congratulations">
        <h1 class="text-white">Proposta: {{ parametro.codigo }}</h1>
        <b-card-text>
          <b-row>
            <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="parametro.foto != null ? parametro.foto.foto : null"
                  :text="avatarText(parametro.segurado.tomador.nomeCompleto)"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0" style="color: white">
                      {{ parametro.segurado.tomador.nomeCompleto }}
                    </h4>
                    <span class="card-text">CPF: {{ parametro.segurado.tomador.cpf }}</span
                    ><br />
                    <span class="card-text"
                      >{{ parametro.origem }}:
                      <span class="badge badge-light-alert badge-pill">{{
                        this.formatarCodigoSequencial(parametro.codigo)
                      }}</span></span
                    ><br />
                    <span v-if="vendedor" class="card-text"
                      >Vendedor: <span class="font-weight-bold"> {{ vendedor }} </span></span
                    >
                  </div>
                </div>
              </div>
            </b-col>

            <b-col cols="12" xl="6">
              <table class="mt-2 mt-xl-0 w-100">
                <caption class="sr-only">
                  Descrição da tabela: Dados de vendas mensais
                </caption>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="BriefcaseIcon" class="mr-75" />
                    <span class="font-weight-bold">Órgão</span>
                  </th>
                  <td class="pb-50">
                    {{ parametro.segurado.orgao.nome }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">Categoria</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ filterInativo(parametro.segurado.situacaoPrevidenciaria) }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon icon="PhoneIcon" class="mr-75" />
                    <span class="font-weight-bold">Telefone</span>
                  </th>
                  <td>
                    {{ parametro.segurado.tomador.telefone }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon icon="AtSignIcon" class="mr-75" />
                    <span class="font-weight-bold">E-mail</span>
                  </th>
                  <td>
                    {{ parametro.segurado.tomador.email }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="3">
              <b-form-group class="label-branco" :label="'Valor da ' + parametro.origem" label-for="valor">
                <b-form-input
                  id="valor"
                  v-model="dadosProposta.valorDaProposta"
                  placeholder="Valor da Proposta"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="label-branco" label="Parcelas" label-for="parcelas">
                <b-form-input
                  id="parcelas"
                  v-model="dadosProposta.quantidadeDeParcelas"
                  placeholder="Parcelas"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="label-branco" label="Vencimento da 1º Parcela" label-for="primeiraParcela">
                <b-form-input
                  id="primeiraParcela"
                  v-model="dadosProposta.vencimentoDaPrimeiraParcela"
                  class="form-control"
                  type="text"
                  placeholder="DD/MM/AAAA"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="label-branco" label="Valor da 1º Parcela" label-for="valorPrimeiraParcela">
                <b-form-input
                  id="valorPrimeiraParcela"
                  v-model="dadosProposta.valorDaPrimeiraParcela"
                  placeholder="Valor 1º Parcela"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group class="label-branco" label="Taxa %" label-for="taxa">
                <b-form-input id="taxa" v-model="dadosProposta.taxa" placeholder="Taxa %" :readonly="true" />
              </b-form-group>
            </b-col>

            <b-col v-if="valorMontanteDisponivel" md="3">
              <b-form-group class="label-branco" label="Montante disponível" label-for="montanteDisponivel">
                <b-form-input
                  id="montanteDisponivel"
                  v-model="montante.disponivel"
                  class="form-control"
                  type="text"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="label-branco" label="Valor total do empréstimo" label-for="totalEmprestimo">
                <b-form-input
                  id="valorTotalEmprestimo"
                  v-model="parametro.valorComIOF"
                  placeholder="Valor total do empréstimo"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>

            <b-col v-if="utilizaIOF" md="3">
              <b-form-group class="label-branco" label="Valor do IOF" label-for="valorIOF">
                <b-form-input id="valorIOF" v-model="parametro.valorIOF" class="form-control" type="text" :readonly="true" />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group class="label-branco" label="Valor total do empréstimo com juros %" label-for="valorTotalComJuros">
                <b-form-input
                  id="valorTotalComJuros"
                  v-model="parametro.valorTotalComJuros"
                  class="form-control"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="label-branco" label="Saldo devedor Inicial" label-for="saldoDevedorInicial">
                <b-form-input id="saldoDevedorInicial" v-model="valorTotalEmprestimo" :disabled="true" />
              </b-form-group>
            </b-col>
            <b-col v-if="parametro.compraDeDivida === true" md="3">
              <b-form-group class="label-branco" label="Valor da Dívida" label-for="valorDaDivida">
                <b-form-input id="valorDaDivida" v-model="parametro.valorDaDivida" :disabled="true" />
              </b-form-group>
            </b-col>
            <!-- Saldo Remanescente -->
            <b-col v-if="parametro.compraDeDivida === true" md="3">
              <b-form-group class="label-branco" label="Saldo Remanescente" label-for="saldo Remanescente">
                <b-form-input id="saldoRemanescente" v-model="parametro.valorRemanescente" :disabled="true" />
              </b-form-group>
            </b-col>
            <!-- Banco-->
            <b-col v-if="parametro.compraDeDivida === true" md="3">
              <b-form-group class="label-branco" label="Banco" label-for="bancoDivida">
                <b-form-input id="bancoDivida" v-model="parametro.nomeBanco" :disabled="true" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-row>
                <b-col md="6" style="margin-top: 23px; display: flex">
                  <b-button
                    v-if="parametro.statusProposta == 'Pendente'"
                    v-show="parametro.acesso.PropostaAprovarProposta"
                    style="display: flex; flex-grow: 1; height: 40px; justify-content: center"
                    class="btn w-50 btn-success"
                    @click="aprovarProposta(parametro.id)"
                  >
                    <feather-icon icon="CheckSquareIcon" size="16" />
                    <span class="align-middle ml-50">Aprovar</span>
                  </b-button>
                </b-col>
                <b-col md="6" style="margin-top: 23px; display: flex">
                  <b-button
                    v-if="parametro.statusProposta == 'Pendente'"
                    v-show="parametro.acesso.PropostaAprovarProposta"
                    style="display: flex; flex-grow: 1; height: 40px; justify-content: center"
                    class="btn w-50 btn-danger"
                    @click="negarProposta(parametro.id)"
                  >
                    <feather-icon icon="XSquareIcon" size="16" />
                    <span class="align-middle ml-50">Negar</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>

      <b-tabs>
        <b-tab>
          <template #title>
            <span>Parcelas</span>
          </template>

          <b-row>
            <b-col md="12">
              <b-table
                striped
                responsive
                class="position-relative"
                :per-page="perPageVisualizarParcelas"
                :current-page="currentPageVisualizarParcelas"
                :items="itemsVisualizarParcelas"
                :fields="fieldsVisualizarParcelas"
                :sort-by.sync="sortByVisualizarParcelas"
                :sort-desc.sync="sortDescVisualizarParcelas"
                :sort-direction="sortDirectionVisualizarParcelas"
              >
                <template #cell(valor)="row">
                  {{ formatarValor(row.item.valor) }}
                </template>
                <template #cell(vencimento)="row">
                  {{ formatarData(row.item.vencimento) }}
                </template>
              </b-table>

              <b-col v-if="itemsVisualizarParcelas.length == 0" md="12">
                <naoEncontradoModal />
              </b-col>

              <b-card-body v-if="itemsVisualizarParcelas.length != 0" class="d-flex justify-content-center flex-wrap pt-0">
                <b-pagination
                  v-model="currentPageVisualizarParcelas"
                  :total-rows="totalRowsVisualizarParcelas"
                  :per-page="perPageVisualizarParcelas"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-card-body>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <span>Termos e Condições</span>
          </template>
          <tabTermos :parametro="parametro" />
        </b-tab>
      </b-tabs>
    </b-form>

    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import {
    detectarNavegador,
    formatarCodigoSequencial,
    formatarData,
    formatarParaNumber,
    formatarValor,
    formatarValorReais,
    isNumber,
  } from '@/libs/utils'
  import naoEncontradoModal from '@/views/error/nao-encontrado-modal.vue'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha.vue'
  import { avatarText } from '@core/utils/filter'
  import * as Math from 'mathjs'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import contratoGeraContrato from '../pages/financeiro/contrato/contratoGeraContrato.vue'
  import tabTermos from './tabTermos/tabTermos.vue'

  export default {
    components: {
      formatarParaNumber,
      ValidationProvider,
      contratoGeraContrato,
      ValidationObserver,
      naoEncontradoModal,
      AutenticaSenha,
      formatarData,
      formatarCodigoSequencial,
      formatarValor,
      formatarValorReais,
      Math,
      tabTermos,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    setup() {
      return {
        avatarText,
      }
    },
    data() {
      return {
        montante: {
          disponivel: undefined,
        },
        isNumber,
        formatarValor,
        formatarData,
        formatarCodigoSequencial,
        geraContrato: {},
        number: {},
        valorTotalEmprestimo: 0,
        recursos: {},
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        sortByVisualizarParcelas: '',
        sortDescVisualizarParcelas: false,
        sortDirectionVisualizarParcelas: 'asc',
        perPageVisualizarParcelas: 6,
        currentPageVisualizarParcelas: 1,
        fieldsVisualizarParcelas: [
          { key: 'parcela', sortable: true },
          { key: 'valor', sortable: true },
          { key: 'vencimento', sortable: true },
        ],

        totalRowsVisualizarParcelas: 0,
        itemsVisualizarParcelas: [],
        taxaShow: {},
        utilizaIOF: false,
        instituto: {},
        vendedor: '',
        valorMontanteDisponivel: 0,
        dadosProposta: {
          valorDaProposta: null,
          quantidadeDeParcelas: null,
          vencimentoDaPrimeiraParcela: null,
          valorDaPrimeiraParcela: null,
          taxa: null,
          valorComIOF: null,
        },
      }
    },
    async mounted() {
      if (this.parametro.usuarioId) {
        this.carregarVendedor()
      }
      this.parametro.valorTotalComJuros = formatarValor(this.parametro.valorTotalComJuros)
      this.parametro.valorComIOF = this.parametro.valorComIOF ? formatarValor(this.parametro.valorComIOF) : this.parametro.valor
      this.parametro.valorIOF = this.parametro.valorIOF ? formatarValor(this.parametro.valorIOF) : formatarValor(0)

      this.carregarParcelas()
      await this.fetchMontanteDisponivel()
      this.taxaShow = parseFloat(this.parametro.taxa.replace(/,/g, '')) / 100

      // NOTE: Caso utilize IOF
      this.dadosProposta.valorDaProposta = formatarValor(this.parametro.valorBaseCalculo)
      this.dadosProposta.valorDaPrimeiraParcela = this.dadosProposta.quantidadeDeParcelas = this.parametro.parcelas
      this.dadosProposta.vencimentoDaPrimeiraParcela = this.parametro.primeiraParcela
      this.dadosProposta.valorDaPrimeiraParcela = this.parametro.valorPrimeiraParcela
      this.dadosProposta.taxa = this.parametro.taxa
      this.dadosProposta.valorComIOF = this.parametro.valorComIOF ? this.parametro.valorComIOF : formatarValor(0)

      const valorDoContrato = Math.bignumber(formatarParaNumber(this.parametro.valor))
      const valorDoContratoComIOF = Math.bignumber(formatarParaNumber(this.parametro.valorComIOF))

      const valorTotalEmprestimo = Number(Math.subtract(valorDoContrato, valorDoContratoComIOF))
      this.valorTotalEmprestimo = formatarValor(Math.add(valorTotalEmprestimo, formatarParaNumber(this.parametro.valorComIOF)))
      this.parametro.valorDaDivida = formatarValor(this.parametro.valorDaDivida)
      this.parametro.valorRemanescente = formatarValor(this.parametro.valorRemanescente)
    },
    methods: {
      async carregarVendedor() {
        await useJwt
          .get(`cadastro/user/${this.parametro.usuarioId}`)
          .then((response) => {
            this.vendedor = response.data.name
          })
          .catch((error) => {
            console.error(error)
          })
      },
      filterInativo(cat) {
        if (cat == 'Inativo') return 'Aposentado'
        return cat
      },
      calculoValorAlocado(item) {
        return Number(item.valorAlocado)
      },
      calculoValorDisponivel(item) {
        return (
          item.valorAlocado +
          (item.valorAmortizacao ? Number(item.valorAmortizacao) : 0) -
          Number(item.montanteUtilizadoCumulativo)
        )
      },
      calculaMontanteAcumulado(data, montantes) {
        // Sort the data based on 'competencia'
        const sortedData = data.sort((a, b) => a.competencia - b.competencia)

        // Calculate the cumulative sum of 'montanteUtilizado'
        let cumulativeSum = 0
        for (const item of sortedData) {
          cumulativeSum += parseFloat(Number(montantes[item.competencia] ?? 0))
          item.montanteUtilizadoCumulativo = cumulativeSum.toFixed(2)
        }

        return sortedData
      },
      async fetchMontanteDisponivel() {
        await useJwt
          .get('recurso/getSaldo/' + String(this.userData.produtoId))
          .then((response) => {
            this.valorMontanteDisponivel = Number(response.data)
            this.montante.disponivel = formatarValor(this.valorMontanteDisponivel)
          })
          .catch((error) => {
            console.error(error)
            this.montante.disponivel = undefined
          })
      },
      async autenticarMethod(data) {
        if (data) {
          const { id } = this.userData

          const isValidCustomerData = await this.validateCustomerData()
          if (!isValidCustomerData) {
            this.$refs['modal-auth-senha'].hide()
            return
          }

          const navegadorUtilizado = detectarNavegador()
          await useJwt
            .pesquisar('proposta/aprovarProposta', {
              idProposta: this.propostaSelecionada,
              produtoId: this.userData.produtoId,
              usuario: id,
              navegadorUtilizado,
            })
            .then(async () => {
              this.$emit('atualizarProposta', this.propostaSelecionada, 'Aprovado')
              const { data } = await useJwt.post('financeiro/GetContratoPelaProposta', { id: this.propostaSelecionada })
              this.$emit('abreModalContrato', data)
            })
            .catch((error) => {
              console.error(error)
              let mensagem = 'Encontramos inconsistências: <br><br>'
              const status = error.response.status
              const message = error.response.data.message

              if (status === 400) {
                if (message === 'Falta de recursos disponíveis.') {
                  mensagem += message
                } else if (message === 'Falta de margem disponível.') {
                  mensagem += message
                } else {
                  mensagem += 'Erro ao aprovar proposta. Dados incorretos validados pelo banco'
                }
              }

              this.$swal({
                title: 'Atenção!',
                html: mensagem,
                icon: 'warning',
                confirmButtonText: 'Fechar',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.$refs['modal-auth-senha'].hide()
            })
        }
      },
      async validateCustomerData() {
        return new Promise(async (resolve, reject) => {
          await useJwt
            .post('cadastro/tomador/getCustomerDataValidationForProposal', {
              tomadorId: this.parametro.segurado.tomador.id,
            })
            .then((response) => {
              if (response.data.isValid) {
                resolve(true)
              } else {
                const listaErros = response.data.erros

                this.$swal({
                  title: 'Atenção!',
                  html: `Para aprovar a proposta é necessário preencher todos os dados do tomador. Lista de erros:<br><br>${listaErros.join(
                    '<br>',
                  )}`,
                  icon: 'warning',
                  confirmButtonText: 'Fechar',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                  buttonsStyling: false,
                })
                resolve(false)
              }
            })
            .catch((error) => {
              console.error(error)
              reject(false)
            })
        })
      },

      negarProposta(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente NEGAR a proposta selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            const { id } = this.userData

            this.$swal({
              title: 'Observação',
              input: 'text',
              allowEscapeKey: false,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary ml-1',
              },
              buttonsStyling: false,
              inputAttributes: {
                autocapitalize: 'off',
                required: true,
              },
              inputValidator: (value) => !value && 'Por favor informe o motivo!',
              showCancelButton: true,
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Fechar',
              showLoaderOnConfirm: true,
              preConfirm(motivo) {
                return useJwt
                  .pesquisar('proposta/negarProposta', {
                    idProposta: item,
                    usuario: id,
                    motivoNegado: motivo,
                  })
                  .then((response) => {})
                  .catch((error) => {
                    console.error(error)
                  })
              },
              allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
              if (result.value) {
                this.$swal({
                  title: 'Atenção!',
                  text: 'Proposta negada com sucesso',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  this.$emit('reloadComponent')
                })
              }
            })
          }
        })
      },
      async aprovarProposta(item) {
        const { tomador } = this.parametro.segurado
        const { segurado } = this.parametro
        let mensagem = 'Tomador não possui o(s) dado(s) bancário(s) cadastrado(s): <br><br>'
        let possuiErro = false
        const montanteDisp = this.valorMontanteDisponivel

        if (montanteDisp <= 0) {
          if (!possuiErro) {
            mensagem =
              'O produto possui a seguinte pendência: <br><br> O montante disponivel não pode ser menor ou igual a 0.<br>'
          } else {
            mensagem +=
              '<br><br>O produto possui a seguinte pendência: <br><br> O montante disponivel não pode ser menor ou igual a 0.<br>'
          }
          possuiErro = true
        }

        if (possuiErro) {
          this.$swal({
            title: 'Atenção!',
            html: mensagem,
            icon: 'warning',
            confirmButtonText: 'Fechar',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Atenção!',
            text: 'Deseja realmente APROVAR a proposta selecionada?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1',
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.propostaSelecionada = item
              this.$refs['modal-auth-senha'].show()
            }
          })
        }
      },
      carregarParcelas() {
        useJwt
          .get(`proposta/carregarParcelas/${this.parametro.id}`)
          .then((response) => {
            const dados = response.data
            this.itemsVisualizarParcelas = dados
            this.totalRowsVisualizarParcelas = dados.length
            // Retornado pois o valor ainda estava vindo como NaN
            this.parametro.valorPrimeiraParcela = formatarValor(this.parametro.valorPrimeiraParcela)
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style>
  .label-branco label {
    color: white;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
</style>
