var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"text-center my-2"},[_vm._v("Valide os dados do tomador")]),_c('validation-observer',{ref:"formAtualizarDadosTomador"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"form-name"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('FeatherIcon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"form-name","name":"form-name","placeholder":"Nome","disabled":_vm.isBusy},model:{value:(_vm.dados.nome),callback:function ($$v) {_vm.$set(_vm.dados, "nome", $$v)},expression:"dados.nome"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"CPF","label-for":"form-CPF"}},[_c('validation-provider',{attrs:{"name":"CPF","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('FeatherIcon',{attrs:{"icon":"BookOpenIcon"}})],1),_c('cleave',{staticClass:"form-control",attrs:{"id":"form-CPF","type":"text","placeholder":"CPF","raw":false,"options":_vm.options.cleaveCPF,"disabled":_vm.isBusy},on:{"input":_vm.removePontos},model:{value:(_vm.dados.cpf),callback:function ($$v) {_vm.$set(_vm.dados, "cpf", $$v)},expression:"dados.cpf"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Matrícula","label-for":"form-matricula"}},[_c('validation-provider',{attrs:{"name":"Matrícula","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('FeatherIcon',{attrs:{"icon":"CreditCardIcon"}})],1),_c('b-form-input',{attrs:{"id":"form-matricula","name":"form-matricula","placeholder":"Matrícula","disabled":_vm.isBusy},model:{value:(_vm.dados.matricula),callback:function ($$v) {_vm.$set(_vm.dados, "matricula", $$v)},expression:"dados.matricula"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Margem Disponível","label-for":"form-margem-disponivel"}},[_c('validation-provider',{attrs:{"name":"Margem Disponível","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('FeatherIcon',{attrs:{"icon":"DollarSignIcon"}})],1),_c('b-form-input',{directives:[{name:"number",rawName:"v-number",value:(_vm.number),expression:"number"}],attrs:{"id":"form-margem-disponivel","name":"form-margem-disponivel","placeholder":"Margem Disponível","disabled":_vm.isBusy},model:{value:(_vm.dados.margem),callback:function ($$v) {_vm.$set(_vm.dados, "margem", $$v)},expression:"dados.margem"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{attrs:{"block":"","variant":"primary","disabled":_vm.isBusy},on:{"click":_vm.atualizarDados}},[_vm._v(" "+_vm._s(!_vm.isBusy ? 'Atualizar Dados' : '')+" "),(_vm.isBusy)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }